@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Ubuntu:wght@400;700&family=Ubuntu+Mono:wght@400;700&display=swap');

html, body { height: 100%;}

body > footer {
  position: sticky;
  top: 100vh;
}


.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  color: black;
}

.payment-item-image-drag{
  width: 200px;
  height: 50px;
  border: 1px dashed #aaa;
  border-radius: 10px;
}



/* dropdown */
.dropdown {
  /* position: relative;
  display: inline-block; */
}

.dropdown-menu {
  position: absolute;
  display: none;
  min-width: 160px;
  max-width: 250px;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 999;
}

.dropdown-menu li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #000;
}

.dropdown-menu li a:hover {
  background-color: #eee;
}

.dropdown-toggle {
  position: relative;
  display: block;
  padding: 8px 16px;
  margin-bottom: 0;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
} 

.dropdown-toggle:hover {
  background-color: #eee;
}

.dropdown-toggle .caret {
  position: absolute;
  display: inline-block;
  right: 8px;
  top: 12px;
  width: 0;
  height: 0;
  border-top: 4px dashed #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}