@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components { 

    .btn-sidebar {
      @apply flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-pink-300 hover:text-pink-800 dark:hover:bg-gray-700
    }
    .btn-common-pink {
      @apply m-1 flex rounded-xl justify-center items-center bg-pink-500 text-white hover:bg-white hover:text-pink-800 hover:border-pink-800 hover:border-2 border-0 p-2 font-bold cursor-pointer;
    }
    .btn-common {
      @apply m-1 flex rounded-xl justify-center items-center bg-slate-500 text-white hover:bg-white hover:text-slate-800 hover:border-slate-800 hover:border-2 border-0 p-2 font-bold cursor-pointer;
    }
    .link-common {
      @apply m-1 items-center underline hover:cursor-pointer;
    }
    .link-align-center {
      @apply text-center justify-center items-center;
    }
  }
  